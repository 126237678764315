<template>
  <div>
    <div class="flex flex-wrap-reverse items-center">
      <div
        v-permission="'questionnaires.create'"
        class="p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary"
        @click="addNew"
      >
        <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
        <span class="ml-2 text-base text-primary">{{ $t('AddNew') }}</span>
      </div>
    </div>
    <Grid :service="service" route_name="questionnaires" order_column="name" ref="grid">
      <template v-slot:filterZone >
        <select-suggestion
          class="w-full lg:w-1/3"
          :label="$t(`fields.discipline`)"
          ref="DisciplineSelect"
          v-model="filters.discipline"
          :itemTextFormat="(model) => { return model.title }"
          :name="`filter_discipline_id`"
          :appendClearOption="true"
          model="Discipline"
        />
      </template>
      <template v-slot:gridThead>
        <vs-th key="id" sort-key="id">ID</vs-th>
        <vs-th key="name" sort-key="name">{{ $t('Name') }}</vs-th>
        <vs-th key="published_at" sort-key="published_at" >{{ $t('data-publicacao') }}</vs-th>
        <vs-th key="start_at" sort-key="start_at">{{ $t('data-inicio-0') }}</vs-th>
        <vs-th key="finish_at" sort-key="finish_at">{{ $t('data-termino') }}</vs-th>
      </template>
      <template v-slot:gridActions>
        <vs-th key="actions">{{ $t('Actions') }}</vs-th>
      </template>
      <template v-slot:gridData="props">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in props.data">
          <vs-td width="5%" key="id">{{ tr.id }}</vs-td>
          <vs-td width="35%" key="name">{{ tr.name }}</vs-td>
          <vs-td width="15%" key="published_at">{{tr.published_at_formatted}}</vs-td>
          <vs-td width="15%" key="start_at">{{ tr.start_at_formatted }}</vs-td>
          <vs-td width="15%" key="finish_at">{{tr.finish_at_formatted}}</vs-td>
          <vs-td width="10%">
            <div class="grid grid-cols-3 gap-8 sm:gap-4 md:gap-4">
              <div class="flex items-center justify-center">
                <feather-icon
                  v-permission="'questionnaires.edit'"
                  icon="EditIcon"
                  svgClasses="h-5 w-5 mb-1 stroke-current text-primary"
                  @click="
                    props.edit(tr.id)
                    $vs.loading()
                  "
                  v-tooltip.top="{
                    content: 'Editar',
                    delay: { show: 1000, hide: 100 }
                  }"
                />
              </div>
              <div class="flex items-center justify-center">
                <feather-icon
                  v-permission="'questionnaires.delete'"
                  icon="Trash2Icon"
                  svgClasses="h-5 w-5 mb-1 stroke-current text-danger "
                  @click="props.destroy(props.data[indextr])"
                  v-tooltip.top="{
                    content: 'Excluir',
                    delay: { show: 1000, hide: 100 }
                  }"
                />
              </div>
              <div class="flex items-center justify-center">
                <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
                  <feather-icon
                    type="filled"
                    icon="MoreVerticalIcon">
                  </feather-icon>
                  <vs-dropdown-menu>
                      <vs-dropdown-item>
                        <div
                          class="flex"
                          style="width: 150px;"
                          v-clipboard:copy="tr.short_url"
                          v-clipboard:success="onSucceddCopy"
                          v-clipboard:error="onErrorCopy"
                        >
                          <div class="w-2/12">
                            <feather-icon
                            icon="LinkIcon"
                            svgClasses="h-5 w-5 mb-1 stroke-current text-primary"
                            v-clipboard:copy="tr.short_url"
                            v-clipboard:success="onSucceddCopy"
                            v-clipboard:error="onErrorCopy"
                            v-tooltip.top-left="{
                              content: 'Copiar link do questionário',
                              delay: { show: 1000, hide: 100 }
                            }"/>
                          </div>

                          <div class="w-10/12">
                            <div class="flex bg-gray-200 float-left">
                              {{ $t('copiar-link-0') }}
                            </div>
                          </div>
                        </div>
                      </vs-dropdown-item>

                      <vs-dropdown-item>
                        <div class="flex"
                          style="width: 150px;"
                          @click="openCorrection(tr.id)"
                        >
                          <div class="w-2/12">
                            <feather-icon
                            icon="CheckCircleIcon"
                            svgClasses="text-success h-5 w-5 mb-1 stroke-current"
                            v-tooltip.top-left="{
                              content: 'Abre a correção das respostas em uma nova aba.',
                              delay: { show: 500, hide: 100 },
                            }"/>
                          </div>

                          <div class="w-10/12">
                            <div class="flex bg-gray-200 float-left">
                              {{ $t('correcao') }}
                            </div>
                          </div>
                        </div>
                      </vs-dropdown-item>

                      <vs-dropdown-item>
                        <div class="flex"
                          style="width: 150px;"
                          @click="openResume(tr.id)"
                        >
                          <div class="w-2/12">
                            <feather-icon
                            icon="BarChartIcon"
                            svgClasses="text-dark h-5 w-5 mb-1 stroke-current"
                            v-tooltip.top-left="{
                              content: 'Abre a o resumo do questionário em uma nova aba.',
                              delay: { show: 500, hide: 100 },
                            }"/>
                          </div>

                          <div class="w-10/12">
                            <div class="flex bg-gray-200 float-left">
                              {{ $t('resumo') }}
                            </div>
                          </div>
                        </div>
                      </vs-dropdown-item>

                      <vs-dropdown-item>
                        <div class="flex"
                          style="width: 150px;"
                          @click="preview(tr.short_url_id)">
                          <div class="w-2/12" @click="preview(tr.short_url_id)">
                            <feather-icon
                            icon="EyeIcon"
                            svgClasses="h-5 w-5 mb-1 stroke-current"
                            @click="preview(tr.short_url_id)"
                            v-tooltip.top-left="{
                              content: 'Pré-visualizar',
                              delay: { show: 500, hide: 100 },
                            }"/>
                          </div>
                          <div class="w-10/12">
                            <div class="flex bg-gray-200 float-left">
                              {{ $t('pre-visualizar') }}
                            </div>
                          </div>
                        </div>
                      </vs-dropdown-item>
                      <!-- Print -->
                      <vs-dropdown-item v-permission="'questionnaires.print'">
                        <div class="flex"
                          style="width: 150px;"
                          @click="print(tr.id)">
                          <div class="w-2/12" @click="print(tr.id)">
                            <feather-icon
                            icon="PrinterIcon"
                            svgClasses="h-5 w-5 mb-1 stroke-current"
                            @click="print(tr.id)"
                            v-tooltip.top-left="{
                              content: $t('print'),
                              delay: { show: 500, hide: 100 },
                            }"/>
                          </div>
                          <div class="w-10/12">
                            <div class="flex bg-gray-200 float-left">
                              {{ $t('print') }}
                            </div>
                          </div>
                        </div>
                      </vs-dropdown-item>
                      <!-- End print-->
                      <vs-dropdown-item>
                        <div
                          class="flex"
                          style="width: 150px;"
                          @click="confirmClone(tr.id)"
                        >
                          <div class="w-2/12">
                            <feather-icon
                            icon="CopyIcon"
                            svgClasses="h-5 w-5 mb-1 stroke-current text-warning"
                            @click="confirmClone(tr.id)"
                            id="trigger"
                            v-tooltip.top="{
                              content: 'Clonar',
                              delay: { show: 1000, hide: 100 }
                            }"/>
                          </div>

                          <div class="w-10/12">
                            <div class="flex bg-gray-200 float-left">
                              {{ $t('clonar') }}
                            </div>
                          </div>
                        </div>
                      </vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </div>
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </Grid>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import ContentQuestionnaireService from '@/services/api/ContentQuestionnaireService'
import DisciplineService from '@/services/api/DisciplineService'
import SelectSuggestion from '@/components/SelectSuggestion.vue'

export default {
  components: {
    vSelect,
    SelectSuggestion
  },
  data: () => ({
    selected: [],
    service: null,
    filters: {
      discipline: null
    }
  }),
  watch: {
    disciplineFilter(val) {
      if (val) {
        this.$refs.grid.handleFilter(this.disciplineFilter, 'discipline_id')
      } else {
        this.$refs.grid.resetFilters()
        this.$refs.grid.fetchGridData(true)
      }
    }
  },
  computed: {
    disciplineFilter() {
      return this.isEmpty(this.filters.discipline) ? '' : this.filters.discipline.id
    }
  },
  methods: {
    addNew() {
      // this.$vs.loading()
      this.$router.push('/questionnaires/create')
      // this.service.create({ name: '', data: null }).then(response => {
      //   this.$router.push(`/questionnaires/${response.id}/edit`)
      //   this.$vs.loading.close()
      // })
    },
    confirmClone(id) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'success',
        title: this.$t('confirmacao'),
        acceptText: this.$t('sim'),
        cancelText: this.$t('nao'),
        text: this.$t('tem-certeza-que-deseja-clonar-este-quest'),
        accept: () => {
          this.clone(id)
        }
      })
    },
    clone(id) {
      this.$vs.loading()
      this.service.clone(id).then(
        response => {
          this.$router.push(`/questionnaires/${response.id}/edit`)
          this.$vs.loading.close()
          this.notifySuccess(
            this.$vs,
            this.$t('questionario-clonado-com-sucesso'),
            2000,
            'center-top'
          )
        },
        error => {
          this.$vs.loading.close()
        }
      )
    },
    onSucceddCopy() {
      this.notifySuccess(this.$vs, `${this.$t('copiado')}!`, 2000, 'center-top')
    },
    onErrorCopy() {
      this.notifyError(this.$vs, this.$t('nao-foi-possivel-copiar'))
    },
    preview(shortUrlId) {
      const routeData = this.$router.resolve({
        name: 'questionnaires_form_answers_preview',
        params: { short_url_id: shortUrlId },
      })
      window.open(routeData.href, '_blank')
    },
    print(questionnaireId) {
      const routeData = this.$router.resolve({
        name: 'questionnaires_print',
        params: {
          id: questionnaireId
        },
      })
      window.open(routeData.href, '_blank')
    },
    openCorrection(id) {
      this.$utils.browser.appNewTab(this, `/questionnaires/${id}/correction`)
    },
    openResume(id) {
      this.$utils.browser.appNewTab(this, `/questionnaires/${id}/resume`)
    }
  },
  beforeMount() {
    this.service = ContentQuestionnaireService.build(this.$vs)
  }
}
</script>

<style scoped lang="scss">
  .vs-tooltip, .vue-tooltip-theme {
    z-index: 99999 !important;
  }

  .vs-dropdown-menu.rightx {
    .vs-dropdown--menu--after {
      right: 10px !important;
      left: unset !important;
    }
  }

  .con-select i {
    margin-right: 4px;
  }
</style>
